<template>


  <div id="app">

    <section class="d-none justify-content-md-between top-header ">
      <div class="d-flex left">
        <div class="contact d-flex align-items-center">
          <i class="far fa-envelope"></i>
          <span>Email :</span> admission.primaire@elbilia.ma
        </div>
        <div class="contact d-flex align-items-center">
          admission.collegelycee@elbilia.ma
        </div>
        <div class="contact d-flex align-items-center">
          <div><i class="fas fa-phone"></i></div>

          <div><span>Téléphone :</span>+212 6 61 40 84 24</div>
        </div>
      </div>

      <div class="edukaBtn d-flex desktopOnly">
        <a href="https://elbilia.eduka.school/registration" target="_blank">
          Eduka
        </a>
        </div>
    </section>
    <section class="first_section ps">
      <div class="row pt-6 d-flex align-items-center">
        <div class="col-md-6 col-sm-12 first_col">
            <div class="logo_section">
              <img src="./assets/elbilia-logo.png" style="width: 200px">
            </div>
            <div class="content">
              <h2 class="text-uppercase">Test d'admission<br>du 27 Mars</h2>
              <h3 class="text-uppercase" style="color:#931131">ouverture des inscriptions</h3>
              <p>Primaire et Collège-lycée<br>Année scolaire 2021/2022</p>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 second_col pt60">
          <div id="form">
            <h3 class="text-center form-title">Inscription au test d'admission (Primaire et collège-Lycée) 27 Mars </h3>
            <p class="text-center form-subtitle">Envie d'avoir plus d'informations ?<br>Remplissez le form ci-dessous</p>
            <form @submit.prevent="sendLead">
              <div class="form-fields">

                <div class="form-group" :class="{ 'form-group--error': $v.full_name.$error }">
                  <input class="form-control" v-model.trim="$v.full_name.$model" placeholder="Votre nom complet"/>
                </div>
                <div class="error validation_error" v-if="!$v.full_name.required">Le champ "Nom complet" est
                  obligatoire
                </div>

                <div class="form-group" :class="{ 'form-group--error': $v.child.$error }">
                  <input class="form-control" v-model.trim="$v.child.$model" placeholder="Nom de votre enfant"/>
                </div>
                <div class="error validation_error" v-if="!$v.child.required">le champ "nom de l'enfant" est
                  obligatoire
                </div>

                <div class="form-group" :class="{ 'form-group--error': $v.email.$error }">
                  <input class="form-control" v-model.trim="$v.email.$model" placeholder="Votre adresse email"/>
                </div>
                <div class="error validation_error" v-if="!$v.email.required">le champ "Email" est obligatoire</div>
                <div class="error validation_error" v-if="!$v.email.email">Adresse email invalide</div>

                <div class="form-group" :class="{ 'form-group--error': $v.phone.$error }">
                  <input type="tel" class="form-control" v-model.trim="$v.phone.$model"
                         placeholder="Votre numéro de téléphone"/>
                </div>
                <div class="error validation_error" v-if="!$v.phone.required">le champ "Téléphone" est obligatoire</div>

                <select required class="form-control" v-model="level" @change="checkSelectValidity">
                  <option value="0" disabled>Niveau scolaire de votre enfant</option>
                  <option value="Primaire">Primaire</option>
                  <option value="Collège-Lycée">Collège-Lycée</option>
                </select>

                <div class="error validation_error cusError" v-if="dropDownError" style="display: block">
                  Le champ "Niveau scolaire" est obligatoire
                </div>
              </div>
              <div class="form-check d-flex align-items-center" :class="{ 'form-group--error': $v.terms.$error }">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model.trim="$v.terms.$model">
                <label class="form-check-label" for="flexCheckChecked">
                  <a style="font-size: 9px" href="https://leonafricain.ma/college-lycee/contact" target="_blank">Mentions légales et données personnelles</a>
                </label>
              </div>
              <div class="error validation_error" v-if="!$v.terms.sameAs">Veuillez accepter tous les termes et conditions avant de soumettre le formulaire</div>
              <div v-if="isSent" class="alert alert-success mt-4" role="alert">
                Votre demande d'inscription a été envoyée à elbilia, nous vous répondrons dans les plus brefs délais.
              </div>
              <center>
                <button type="submit" class="sendBtn">
                  Me contacter
                </button>
              </center>

            </form>
          </div>

        </div>
      </div>
    </section>
    <section class="second_section ps">
      <div class="row switcher_row justify-content-center align-items-center">
        <div class="col-md-4 switchCol">
          <Button class="switchBtn " @click="changeContent(0)" :class="{ activeSwitchBtn: isActiveTab(0) }">
            Primaire Elbilia
          </Button>
        </div>
        <div class="col-md-4 switchCol">
          <Button class="switchBtn" @click="changeContent(1)" :class="{ activeSwitchBtn: isActiveTab(1) }">
            Collège-Lycée Elbilia
          </Button>
        </div>

      </div>
      <div class="row">
        <div class="col-md-6 pt60 video_row">

          <div v-if="!showVideo" class="vide_thumbnail d-flex align-items-center" @click="showVideo=true"
               :style="{ backgroundImage: `url(${imgUrl(selectedContent.video_thumb)})` }"
          >
            <i class="fas fa-play"></i>
          </div>
          <video-embed v-else :params="{autoplay: 1}" :src="selectedContent.video"></video-embed>

        </div>
        <div class="col-md-6 pt60">
          <h2>Pourquoi choisir Elbilia</h2>
          <ul style="padding:0">
            <li v-for="item in selectedContent.pf" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row cta pt60 d-flex align-items-center">
        <div class="col-md-8 d-flex align-items-center">
          <p class="title" style="margin-bottom: 0">Inscrivez-vous au test d'admission (Primaire et Collège-Lycée) du 27 Mars</p>
        </div>
        <div class="col-md-4 d-flex align-items-center ">
          <div class="centermg">
            <button class="btn" v-scroll-to="'#form'">M'inscrire</button>

          </div>
        </div>
      </div>

    </section>
    <section class="third_section ps">
      <div class="row justify-content-center">
        <h2>Notre procédure d'admission</h2>
      </div>
      <div class="row last_row">
        <div class="col-md-6 left">
          <ul>
            <li>Remplir le formulaire en ligne</li>
            <li>Télécharger la fiche d'inscription électronique et l'ensemble des documents relatifs à l'inscription</li>
            <li v-html="selectedContent.pro3"></li>
            <li>Organisation d'une commission d'admission en collaboration avec les équipes pédagogiques pour étude de dossier</li>
            <li>Communication du service des admissions pour vous informer des résultats et de la suite du processus d'inscription</li>
          </ul>
            <p style="margin-top: 20px;margin-bottom: 20px">Dossier {{ selectedContent.class }}</p>
          <ul>
            <li>Téléchargement du formulaire en ligne : <a target="_blank" :href="selectedContent.online_form" class='gold'>ICI</a></li>
            <li>Téléchargement du dossier d'inscription : <a target="_blank" :href="selectedContent.documents" class='gold'>ICI</a></li>
          </ul>
        </div>
        <div class="col-md-6 right">

          <a  v-bind:href="'mailto:'+selectedContent.email"  class="btn thirdBtn">
            <div class="col-8 ">
              <p>Contactez-nous</p>
            </div>
            <div class="col-4 np">
              <span>En savoir plus</span>

            </div>
          </a>

        </div>
      </div>

      <div class="row process">
   <div class="fifth">
                  <div class="box">
                    <p>Remplir le formulaire en ligne</p>
                  </div>
                </div>
                <div class="fifth">
                  <div class="box">
                    <p>Télécharger la fiche d'inscription et l'ensemble des documents relatifs à l'inscription</p>
                  </div>
                </div>
                <div class="fifth">
                  <div class="box">
                    <p>Envoyer la fiche électronique et les relevés de notes 2019/2020 et 2020/2021 par email</p>
                  </div>
                </div>
                <div class="fifth">
                  <div class="box">
                    <p>Étude de dossier</p>
                  </div>
                </div>
                <div class="fifth last">
                  <div class="box">
                    <p>Information des résultats et de la suite du processus d'inscription</p>
                  </div>
                </div>
      </div>

    </section>
    <section style="background-color: #fff">
      <div class="carousel_cs">
        <VueSlickCarousel  v-bind="settings">
          <div class="gal_img"><img src="./assets/slide1.jpg"></div>
          <div class="gal_img"><img src="./assets/slide2.jpg"></div>
          <div class="gal_img"><img src="./assets/slide3.jpg"></div>
          <div class="gal_img"><img src="./assets/slide5.jpg"></div>
          <div class="gal_img"><img src="./assets/slide6.jpg"></div>
          <div class="gal_img"><img src="./assets/slide7.jpg"></div>
          <div class="gal_img"><img src="./assets/slide8.jpg"></div>
          <div class="gal_img"><img src="./assets/slide9.jpg"></div>
        </VueSlickCarousel>
      </div>
    </section>
    <section id="footer">
      <div class="row">
        <div class="col-md-6">
          <p class="rights">Tous droits réservés Elbilia 2021</p>
        </div>
        <div class="col-md-6">
          <div class="social_icons text-right">
           <a target="_blank" href="https://www.facebook.com/lyceeElbiliaInternational"><i class="fab fa-facebook-f"></i></a>
            <a target="_blank" href="https://www.instagram.com/lyceeelbiliainternational/"><i class="fab fa-instagram"></i></a>
          </div>
        </div>

      </div>
    </section>


  </div>
</template>
<script src="https://kit.fontawesome.com/your-code-number.js"></script>
<script>
import {required, email, sameAs} from 'vuelidate/lib/validators'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default{
  name: 'App',
  components : {
    VueSlickCarousel
  },

  data: function () {
    return {
      full_name: '',
      child: '',
      email: '',
      phone: '',
      level: '0',
      terms : false,
      dropDownError: false,
      isSent: false,
      showVideo: false,
      submitStatus: null,
      selectedContent: {},
      content:
        [{
        class:"primaire",
        video: 'https://youtu.be/RI_vUOUDs3g',
          video_thumb : 'primaire_thum.png',
        pf: ['Un projet pédagogique inscrit dans la continuité',
          'Enseignement de l’anglais dès la 1ère année primaire.',
          'Initiation aux potentialités numériques dès la 1 ère année primaire',
          'Activités périscolaires et parascolaires tout au long de l’année',
          'Pratique sportive exigeante',
          'Initiation à une palette d’art: expression corporelle, théâtre, photographie, cinéma, calligraphie, musique et chant, arts plastiques …',
          'Enrichissement à travers les manifestations culturelles: Projet d’école clôturé par la présentation un spectacle et une exposition',
          'Programme annuel d’épanouissement et de développement personnel en partenariat avec le centre EPANWE',


        ],
        pa: [
          "Inscription en ligne sur : <a class='gold' href='https://admission.leonafricain.ma'>https://admission.leonafricain.ma</a>",
          "La gestion des admissions se fera sur le portail EDUKA à l’adresse suivante : <a class='gold' href='https://leonlafricain.eduka.school/login'>https://leonlafricain.eduka.school/login</a> dès le 15 février avec un suivi en ligne de votre compte.",
          " Un tutoriel est à votre disposition <a href='#' class='gold'>ici</a>, pour suivre les étapes suivantes: <a class='gold' href='https://leonlafricain.eduka.school/login'>https://leonlafricain.eduka.school/login</a> dès le 15 février avec un suivi en ligne de votre compte.",
        ],
        procedure: [
          'Création compte Eduka',
          'Pré-inscription',
          'Validation',
          'Compléter dossier d\'inscription',
          'Entretien personnalisé',
          'Réponse admission',
          'Paiement des frais d\'inscription'
        ],
          date_test : '20 Mars',

          email : "admission.primaire@elbilia.ma",
          payment_link : "https://drive.google.com/drive/folders/1htpmO5QLZDqgefpD5XVkYX7UxPldZSjW",
          online_form : "https://admission.leonafricain.ma/#/inscriptions/2/6/1",
          documents : "https://drive.google.com/drive/folders/1Th_K_oaF7WTbCc4zESNQZLXv41pH7uqB",
          pro3 : "Envoyer la fiche électronique renseignée en accompagnant les relevés de notes 2019/2020 et 2020/2021 à l'adresse électronique : <span class='gold'>admission.primaire@elbilia.ma</span> pour les niveaux du C1 au C6",
      },
        {
          class:"collège-lycée",

          pf: [
              'Un projet pédagogique inscrit dans la continuité',
            'Enseignement de l’espagnol dès la 1 ère année collège',
            'Développement des compétences numériques',
            'Activités périscolaires et parascolaires tout au long de l’année',
            'Infrastructure répondant à tous les besoins (CDI, Laboratoires, piscine, amphithéâtre …)',
            'Cours de soutiens pour les élèves en difficulté',
            'Pratique sportive exigeante',
            'Programme annuel d’épanouissement et de développement personnel en partenariat avec le centre EPANWE pour la 1 ère année du collège',
            'Activités challengeantes: Olympiades de comptabilité, Teen Business, L’économiste Elbilien',
            'Stages immersion en entreprise et des interventions métiers'
          ],
          date_test : '27 Mars',
          video: 'https://youtu.be/8TJC7PIHL6E',
          video_thumb : 'lycee_thumb.jpg',
          procedure: [
            'Création compte Eduka',
            'Pré-inscription',
            'Validation',
            'Compléter dossier d\'inscription',
            'Paiement des frais de test',
            'TEST',
            'Réponse admission',
            'Paiement des frais d\'inscription'

          ],
          pa: [
            "Inscription en ligne sur : <a class='gold' href='https://admission.leonafricain.ma'>https://admission.leonafricain.ma</a>",
            "La gestion des admissions se fera sur le portail EDUKA à l’adresse suivante : <a class='gold' href='https://leonlafricain.eduka.school/login'>https://leonlafricain.eduka.school/login</a> dès le 15 février avec un suivi en ligne de votre compte.",
            "Un tutoriel est à votre disposition <a href='#' class='gold'>ici</a>, pour suivre les étapes suivantes: <a class='gold' href='https://leonlafricain.eduka.school/login'>https://leonlafricain.eduka.school/login</a> dès le 15 février avec un suivi en ligne de votre compte.",
          ],
          email : "admission.collegelycee@elbilia.ma",

          payment_link : "https://drive.google.com/drive/folders/1gJKsbqjFl-TOb8mKRVSRqG3RAcYcbCae",
          online_form : "https://admission.leonafricain.ma/#/inscriptions/2/4/1",
          documents : "https://drive.google.com/drive/folders/1vW5AONwBVCZtzdjz-AUApqJCA4r6r25Y",
          pro3 : "Envoyer la fiche électronique renseignée en accompagnant les relevés de notes 2019/2020 et 2020/2021 à l'adresse électronique : <span class='gold'>admission.collegelycee@elbilia.ma</span> pour les niveaux de 1AC à 2ABAC",

        },
      ],
      selectedTab: 0,
      settings :  {
        arrows : true,
        centerMode: true,
        centerPadding: "10%",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        dots: true,
        responsive: [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow":1,
              "slidesToScroll": 1,
              "initialSlide": 1
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]

      },
    };
  },
  validations: {
    full_name: {
      required,
    },
    email: {
      email,
      required,
    },
    phone: {
      required,
    },
    child: {
      required,
    },
    terms : {
      sameAs: sameAs( () => true )
    }
  },
  methods: {
    sendLead: function () {
      this.$v.$touch()

      if (this.level == 0) {
        this.dropDownError = true;
        this.submitStatus = 'ERROR'
      } else {

        this.dropDownError = false;
      }
      if (this.$v.$invalid || this.dropDownError) {
      } else {
        var Airtable = require('airtable');
        var base = new Airtable({apiKey: 'keySblfZGttKljPgN'}).base('appQrMCtNaJqkXEFS');

        base('Leads').create([
          {
            "fields": {
              'Nom complet': this.full_name,
              "Nom d'enfant": this.child,
              "email": this.email,
              "téléphone": this.phone,
              "Niveau scolaire": this.level,
            }
          },

        ], function (err, records) {
          if (err) {
            return;
          }else{
            fbq('track', 'Lead');
            window.location.href = "/merci";
          }
        });


      }


    },
    changeContent: function (index) {
      this.showVideo=false;
      this.selectedTab = index;
      this.selectedContent = this.content[index]
    },
    isActiveTab: function (index) {
      if (index == this.selectedTab) {
        return true;
      }
    },
    imgUrl : function(img) {
      return require(`@/assets/${img}`)
    },
    checkSelectValidity(){
      if (this.level == 0) {
        this.dropDownError = true;
        this.submitStatus = 'ERROR'
        console.log('dropdown error')
      } else {
        this.dropDownError = false;
      }
    }

  },

  created() {
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '433815331041475');
    fbq('track', 'PageView');
    this.selectedContent = this.content[0];
  }

}
</script>
<style>
body {
  background-color: #931131 !important;
  font-family: Montserrat;
}

p {
  font-size: 17px;
}

div#app {
  margin: 0;
}
.top-header {
  font-size: 14px;
  background-color: #535353;
  color: #f8bb52;
}
.top-header .left{
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;

}

.top-header .contact svg {
  font-size: 27px;
  padding-right: 8px;
}

.top-header span {
  color: #fff;
  font-weight: 600;
  padding-right: 6px;
  font-size: 13px;
}

.slick-next {
  right: 25px;
}
.slick-prev {
  z-index: 1;
  left: 10px;
}
.slick-prev:before, .slick-next:before{

  font-size: 40px;
}

.first_section {
padding-top: 0;
padding-left: 0;
  padding-bottom: 100px;
background-image: url('./assets/bg_mobile.png');
background-size: cover;
background-repeat: no-repeat;
}

.first_section .first_col .content {
padding-top: 60px;
padding-right: 100px;
}

.first_section .second_col {
padding-top: 60px;
padding-left: 140px;

}



h2 {
text-align: left;
font-family: Montserrat;
font-size: 2.2rem !important;
font-weight: 700 !important;
color: #931131;
}

.contet h2 {
font-size: 37px;

}

#lead_form input, #lead_form select {
margin-top: 10px;
}

#lead_form {
background: #fff;
padding: 50px;
border-radius: 30px;
box-shadow: 0px 2.5px 29.3px 3.3px rgba(0, 0, 0, 0.1);
}

.form-title {
font-size: 23px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #005a9d;
}
.carousel_cs{
  padding-top: 60px;
}
.form-subtitle {
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #38444d;
}

#lead_form button {
padding: 14px 19.5px 16.5px 20.5px;
object-fit: contain;
border-radius: 5px;
box-shadow: 0px 2.5px 29.3px 3.3px rgba(0, 90, 157, 0.3);
background-color: #005a9d;
font-size: 18px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #f8f8f8;
margin-top: 15px;
}

.form-fields input, .form-fields select {
height: 40px !important;
}

.form-group {
margin-bottom: 0 !important;
}


#lead_form center {
margin-bottom: -70px;
}

.logo_section {
padding-top: 60px;
}

.pt60 {
margin-top: 60px;
}

section.ps {
padding-right: 10%;
padding-left: 10%;
}

.vide_thumbnail {
cursor: pointer;
margin-top: 30px;
background-image: url("./assets/thumbnail.png");
height: 300px;
width: 90%;
background-size: cover;
border-radius: 10px;
}

.vide_thumbnail svg {
color: #fff;
font-size: 60px;
margin: 0 auto;
}

.schools_dropdown .btn {
background-color: #fff;
color: #931131;
font-weight: 500;
padding-right: 30px;

}

.schools_dropdown .dropdown-toggle::after {
margin-left: 30px;
color: #000;
}

.second_section h2 {
color: #f8bb52;
padding-bottom: 20px;

}

.second_section ul li {
color: #fff;
font-size: 17px;
list-style: none !important;
margin-bottom: 10px;

}
.second_section{
  padding-top: 60px;
  padding-bottom: 60px;
}
.second_section ul li::before {
content: "\25AA";
color: #cbb05a;
font-weight: bold;
display: inline-block;
width: 1em;
margin-left: 0;
}

.cta {
padding: 20px;
border-radius: 15px;
box-shadow: 0px 2.5px 29.3px 3.3px rgb(32 32 32 / 68%);
background-color: #ffffff;
position: relative;
z-index: 99;
margin-bottom: -75px;
}

.cta button {
margin-top: 10px;
box-shadow: 0px 2.5px 29.3px 3.3px rgb(248 187 82 / 30%);
border: 1px solid #f8bb52;
border-radius: 5px;
color: #fff;
background: #f8bb52;
font-family: Montserrat;
font-size: 14px;
font-weight: bold;
padding: 10px 25px;
}

.cta button:hover {
color: #fff
}

div#form {
background: #fff;
padding: 20px;
border-radius: 20px;
}

.sendBtn, .sendBtn:hover {
display: block;
background: #f8bb52;
font-size: 17px;
font-weight: bold;
color: #fff;
margin-bottom: -35px;
margin-top: 30px;
padding: 15px 25px;
border-radius: 10px;
border: none;
}

.sendBtn:hover {
color: #fff;
}

.cta .title {
margin-bottom: 5px;
font-size: 25px;
line-height: 29px;
font-weight: bold;
color: #931131;

}

.cta span {
color: #f8bb52
}

.centermg {
margin: 0 auto;
}

#footer {
padding: 125px !important;
background: #fff;
}

#footer .rights {
color: #931131;
font-weight: 600;
}

#footer .social_icons svg {
font-size: 1.6em;
width: 1.6em;
height: 1.6em;
text-align: center;
/* line-height: 4.6em; */
  background: #931131;
  color: #fff;
  border-radius: 0.8em;
  padding: 10px;
  margin-right: 5px;
}

#form .form-control {
  margin-top: 10px;
}

@media only screen and (max-width: 1024px) {
  .first_section {
    background-size: cover;
  }

  .fifth .box {
    width: 150px !important;
    height: 150px !important;
  }

  .last_row .right .btn span {
    font-size: 11px;
  }

  .last_row .right .col-8 p {
    font-size: 20px;
  }
  .top-header .left{
    flex-direction: column;
    align-items: center;
  }

}

/* Mobile Version */
@media only screen and (max-width: 800px) {
  .first_section {
    background-image: unset;
    background-size: cover;
    background-repeat: no-repeat;
  }
  section.first_section.ps {
    padding-right: 0;
    padding-left: 0;
  }
  .first_section .first_col {
    background-image: url("./assets/bg_mobile.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-right: 10% !important;
    padding-left: 10% !important;
  }

  .first_section .first_col .content {
    padding-right: 0;
  }

  .first_section .second_col {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: -50px;
  }
  #lead_form {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pt60 {
    padding-left: 0;
    padding-right: 0;
  }

  .vide_thumbnail {
    width: 100%;
  }
  .second_section{
    padding-top: 0;
  }
  .second_section ul {
    margin: 0;
    padding: 0;
  }

  .top-header {
    display: block !important;
  }

  .contact {
    padding-right: 0 !important;
    justify-content: center;
    padding-bottom: 5px;
  }

  .second_section .col-md-6.pt60 {
    padding-right: 0;
    padding-left: 0;
  }

  .levels_dropdown .btn {
    width: 100%;
  }

  .cta .title, .cta .subtitle {
    text-align: center;
  }

  .top-header {
    flex-direction: column;
  }

  .first_section .second_col {
    padding-top: 0;
  }

  .first_section .first_col {
    padding-left: 60px;
    padding-bottom: 35%;
  }

  .third_section h2 {
    text-align: center;
    padding-bottom: 40px;
  }

  .process {
    background-image: unset !important;
  }

  .fifth {
    width: 50% !important;
  }

  .fifth .box {
    width: 90% !important;
    margin-bottom: 10px !important;
    margin-bottom: 10px !important;
  }

  .fifth.last {
    width: 100% !important;
  }

  .fifth.last .box {
    width: 96% !important;
  }
  .top-header .left{
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .row.last_row .left {
    padding: 10px 0;
  }

  .last_row a.btn {
    width: 100% !important;
  }

  section#footer {
    padding-top: 60px !important;
  }

  .edukaBtn {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    justify-content: center !important;
    font-size: 20px !important;
  }

  .video_row {
    margin-top: 10px;
  }

  button.switchBtn {
    margin-bottom: 10px;
  }

  .last_row .right {
    padding: 0;
  }
  .switchCol{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

}

iframe {
  width: 100%;
  margin-top: 30px;
}

.form-group__message, .error {
  font-size: 0.75rem;
  line-height: 1;
  display: none;
  margin-left: 14px;
  margin-top: -0.6rem;
  margin-bottom: 0.6rem;
}

.form-group--error + .form-group__message, .form-group--error + .error {
  display: block;
  color: #f57f6c;
}

.switchBtn {
  border: 1px solid #fff;
  color: #f8bb52;
  width: 100%;
  background: unset;
  padding: 7px;
  border-radius: 5px;
}

.activeSwitchBtn {
  background-color: #fff;
  color: #f8bb52
}

.third_section {
  padding-top: 60px;
  background-color: #fff;
}

.third_section h2 {
  font-size: 30px !important;
  font-weight: 700 !important;
  color: #38444d;
  padding-bottom: 60px;
}

.fifth {
  width: 20%;
}

.fifth .box {
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2.5px 29.3px 3.3px rgba(32, 32, 32, 0.15);
  background-color: #931131;
  color: #fff;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 200px;

}

.fifth .box p {
  width: 100%;
  margin-bottom: 0;
  text-align: center;
}

.process {
  background-image: url("./assets/line.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-position-y: center;
  background-position-x: center;
}



.last_row ul {
  list-style: none;
}

.last_row ul li::before {
  content: "\25AA";
  color: #cbb05a;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.last_row ul li {
  padding-top: 5px;
  margin-left: -37px;

}

.last_row a.btn {
  display: flex;
  width: 80%;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 2.5px 29.3px 3.3px rgba(32, 32, 32, 0.15);
}

.last_row a.btn p {
  font-size: 25px;
  margin-bottom: 0;
}

.last_row a.btn .col-4 {
  display: flex !important;
}

.last_row a.btn span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.last_row .secondBtn span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #37454d !important;
}

.last_row .firstBtn, .last_row .firstBtn:hover {
  background-color: #f8bb52;
  color: #931131;
}

.last_row .secondBtn, .last_row .secondBtn:hover {
  background-color: #fff;
  color: #f8bb52;

}

.last_row .thirdBtn, .last_row .thirdBtn:hover {
  background-color: #000;
  color: #f8bb52;

}

.last_row .left {
  display: flex;
  flex-direction: column;
}

.last_row .right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.gold {
  color: #f8bb52;
}

.last_row .btn .col-8 {
  text-align: left;
}

.form-group__message, .error {
  margin-top: 5px;
}

.cusError {
  color: #f57f6c;
}
a.gold:hover {
  color: #37454d;
}
.np{
  padding: 0 !important;
}
.gal_img{
  padding : 10px;
}
.gal_img img {
  width: 100%;
  /* height: 100%; */
  height: 400px;
  object-fit: cover;
}
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
div.edukaBtn{
  display: none !important;
  background: red;
  padding: 0 60px;
  align-items: center;
  background-color: #f8bb52;
  color: #fff;
  font-size: 17px;
  font-weight: 800;
  border-bottom: 5px solid #9c853f;
}

.edukaBtn a{
  color: #fff;
}
.edukaBtn a:hover{
  color: #37454d;
}
#footer p{
  margin-bottom: 0;
}
#footer .row{
  align-items: center;
}
.contact {
  padding-right: 10px;

}
</style>


