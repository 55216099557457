<template>
  <div id="app">
    <section class="">
      <img src="./assets/logo.png" width="200px" style="padding-bottom: 40px">
      <h1>Merci</h1>
      <div class="alert alert-success mt-4" role="alert">
        Votre demande d'inscription a été envoyée au Lycée Français Léon l’Africain, nous vous répondrons dans les plus brefs délais.
      </div>
    </section>

  </div>
</template>
<script src="https://kit.fontawesome.com/your-code-number.js"></script>
<script>

export default{
  name: 'App',
  components : {

  },
  beforeCreate: function() {
    document.body.className = 'thank_body';
  },
  created() {
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '433815331041475');
    fbq('track', 'PageView');
  }
}
</script>
<style>
.thank_body{
  background-image: url('./assets/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 100px;
}
.alert-success{
  margin-left: 100px;
  margin-right: 100px;
}
h1{
  color: rgb(0, 90, 157);
}
</style>

